<script setup lang="ts">
  import { formatText } from '~/utils/format-text'
  import type { PloneCompetition } from '~/types/Plone'
  import { formatDate } from '~/utils/date'
  import { isAfter } from 'date-fns'

  const props = defineProps<{
    data: PloneCompetition
    sizes?: string
  }>()

  const isAfterDate = props.data?.date
    ? isAfter(new Date(), new Date(props.data?.date))
    : false
</script>

<template>
  <app-card class="item-competition">
    <template #media>
      <nuxt-link
        v-if="data.image"
        :to="data['@id']">
        <nuxt-picture
          v-if="data.image"
          :src="data.image.download"
          :sizes="sizes"
          :width="data.image.width"
          :height="data.image.height"
          :alt="data.title"
          quality="85"
          loading="lazy"/>
      </nuxt-link>
    </template>
    <template #header>
      <h3 class="u-fs--400">
        <nuxt-link :to="data['@id']">
          {{ formatText(data.title) }}
        </nuxt-link>
      </h3>
    </template>
    <template #meta>
      <div v-if="data.date_type && data.date">
        {{ data.date_type }} <span class="item-competition__date">{{ formatDate(data.date) }}</span>
      </div>
      <div
        class="item-competition__regularity"
        v-if="data.competition_regularity">
        <regularity-dot
          :label="data.competition_regularity.title"
          :regularity="data.competition_regularity.token"/>
      </div>
    </template>
  </app-card>
</template>

<style scoped>
  .item-competition {
    position: relative;
  }

  .item-competition__date {
    white-space: nowrap;
  }

  .item-competition__regularity {
    position: absolute;
    display: flex;
    bottom: var(--gap-150);
    right: var(--gap-150);
  }
</style>
